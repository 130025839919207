.tab{
    zoom: 0.85;
    -ms-zoom: 0.85;
    -webkit-zoom: 0.85;
    -moz-transform: scale(0.85);
    -moz-transform-origin: left top;
}

.portfolio_header{
    zoom: 0.9;
    -ms-zoom: 0.9;
    -webkit-zoom: 0.9;
    -moz-transform: scale(0.9);
    -moz-transform-origin: left top;
}