/* .EventCardContainer{
    background-color: aqua;
} */

/* .EventCard .card-text p{
    margin-top: -5% !important;
} */

.card-text {
    text-align: left;
    margin-top: 15px;
    font-size: 12px;
    color: black;
}
.card:hover{
    border:none;
}
.PageTitle{
    text-align: center !important;
    
}

.PageTitle{
    background-color: #1A2E5A !important;
}
/* .search{
    background-color: #1A2E5A !important;
} */
.searchbtn{
    margin-top:0;
    border: none !important;;
    background-color: #F9A826 !important;
}
.stretched-link {
    color: #000000;
    text-decoration: none;
  }

.stretched-link:hover{
    color:inherit;
  }