@media only screen and (max-width: 600px){
    .container .row{
        padding-top: 1.5em;
        width: fit-content;
        /* padding-left: 10px;
        padding-right: 10px; */
    }

    #signinLogo{
        max-width: 90%;
    }
}

body {
    color: #1A2E5A;
}

h1 {
    text-align: center;
    font-family: Poppins;
}

p {
    text-align: center;
    font-family: Open Sans;
}

Form {
    font-family: Open Sans;
    align-content: center;
}

.btn-primary{
    background-color:#1A2E5A !important; 
    margin-top: 10px;
}

/* #loginForm.container{
    padding-top: 8em;
} */

.SignIn{
    margin-top: 15px;
}

#top {
    width: 338.75px;
    height: 147.84px;
    position: auto;
    top: 181px;
    left: 362px;
    transform: rotate(20deg);
}

#left-img {
    width: 379.63px;
    height: 336.99px;
    top: 340px;
    left: 90px;
}

#right-img {
    width: 323.3px;
    height: 256.17px;
    /* #top: 181px; */
    bottom: 34px;
    left: 350px;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#loginForm{
    transform: scale(85%);
}