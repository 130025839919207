#course-header {
    margin-top: 3em;
    background-color: #1A2E5A;
    color: white;
    width: 100%;
    padding-top: 1em;
    text-align: left;
}

.container .row {
    padding-top: 0;
}

.container {
    max-width: '100%';
}

#filter-undergrduate {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 20px;
    padding-bottom: 10px;
    text-align: left;
    /* 
    transform-origin: center center; */
    transform: scale(85%);
    z-index: 1;
}

.course-header .h1 {
    text-align: left;
    font-size: 18px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    padding-left: 3.5em;
    padding-right: 3.5em;
    padding-bottom: 0.8em;
}

.flex-container>div {
    background-color: #f1f1f1;
    margin: 5px;
    width: 100%;
}

.button-filter {
    margin: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
}

label {
    padding-top: 10px;
}

.dropdown-heading-value {
    font-size: 13px;
}

/* .course-container {
    transform: scale(95%);
} */