body, html {
    height: 100%;
}

/* 
.bg {
    background-image: url("../../assets/RSCAREA\ Home\ Page.svg");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.background-image {
    background-image: url("../../assets/blogging.svg");
    background-size: cover;
    background-position: top;
    min-height: 100%;
    height: 100vh;
    position: relative;
}

a.home-button.btn.btn-primary {
    /* background-color: #F9A826 !important; */
    /* position: absolute;
    left: 70px;
    right: 300px;
    bottom: 100px; */
    width: 30%;
    border-color: transparent;
}

@media only screen and (max-width: 767px) {
    #get-start {
        width: -webkit-fill-available;
        position: static;
        display: flex;
        bottom:0;
    }
}