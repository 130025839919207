#dt-header {
    margin-top: 3em;
    background-color: #1A2E5A;
    color: white;
    width: 100%;
    padding-top: 1em;
    text-align: center;
    justify-content: center;
}

.container .row {
    padding-top: 0;
}

.container {
    max-width: '100%';
    justify-content: center;
}


.dt-header .h1 {
    text-align: center;
    font-size: 18px;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    padding-left: 3.5em;
    padding-right: 3.5em;
    padding-bottom: 0.8em;
}

.flex-container>div {
    background-color: #f1f1f1;
    margin: 5px;
    width: 100%;
}

.button-filter {
    margin: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
}

label {
    padding-top: 10px;
}

.dropdown-heading-value {
    font-size: 13px;
}

.nav-item{
    float: left;
    position: absolute;
}

.navbar-light .navbar-nav .active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: " ";
    border-bottom: 5px solid #5BC0EB;
}

.fablab{
    width: 50%;
    height: 50%;
    align-items: center;
    align-self: center;
}