.navbar-brand{
    font-size: 10px;
    text-align: center;
    width: 100%;
    margin-top: auto;
    /* bottom: 0; */
}

/* body { 
    padding-bottom: 70px; 
} */

footer {
    margin-top: 2em;
    /* position: absolute; */
    /* bottom: 0;
    height: 100px; */
  }
