#course-header {
    margin-top: 3em;
    background-color: #1A2E5A;
    color: white;
    width: 100%;
    padding-top: 2em;
    /* padding-bottom: 2em; */
    justify-content: center;
    text-align: center;
}

.compare-title{
    font-family: Open Sans Light;
    font-size: 20px;
}
