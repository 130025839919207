#course-header .h1 {
    text-align: center;
}

#course-detail-header{
    margin-top: 3em;
    background-color: #1A2E5A;
    color: white;
    width: 100%;
    padding-top: 3em;
    padding-bottom: 2em;
    justify-content: center;
    text-align: center;
}


.details {
    padding-top: 40px;
    padding-bottom: 3em;
}

.details-header {
    padding-top: 2em;
}

.details p {
    text-align: left;
}

.col-md-4 {
    padding-top: 20px;
}

.col-md-8 {
    padding-top: 20px;
}

.btn {
    border: none;
    box-shadow: none;
    color: white;
    font-size: 26px;
    cursor: pointer;
}

.btn:hover {
    font-weight: bold;
    box-shadow: none;
    background-color: none;
}

a.moreInformation.btn.btn-primary.btn-lg {
    font-family: Open Sans;
    font-size: large;
    background-color: #1A2E5A;
    color: #F9A826;
    border: none;
}

a.moreInformation.btn.btn-primary.btn-lg:hover {
    color: #1A2E5A;
    background-color: #F9A826 !important;
}

.flex-box {
    display: flex;
    justify-content: flex-end;
}

.detail-title h5 {
    font-size: 16px;
}

.detail {
    font-size: 13px;
}